import { useState } from "react"
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Input,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import { CategoryQuestions } from "./types"
import ColumnHeaders from "./ColumnHeaders"
import QuestionRow from "./QuestionRow"
import { PageContainer } from "../../components"

export const JeopardyMainPage = () => {
  const [edit, setEdit] = useState<boolean>(false)

  const [data, setData] = useState<Array<CategoryQuestions>>([
    {
      columnName: "Category 1",
      questions: {},
    },
    {
      columnName: "Category 2",
      questions: {},
    },
    {
      columnName: "Category 3",
      questions: {},
    },
    {
      columnName: "Category 4",
      questions: {},
    },
    {
      columnName: "Category 5",
      questions: {},
    },
    {
      columnName: "Category 6",
      questions: {},
    },
  ])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [currentQuestion, setCurrentQuestion] = useState<string | undefined>()

  const openQuestion = (content: string) => {
    setCurrentQuestion(content)
    setOpenDialog(true)
  }
  const actions = [
    {
      title: edit ? "View" : "Edit",
      onClick: () => {
        setEdit((prev) => !prev)
      },
    },
    {
      title: "Import",
      onClick: () => {
        const input = window.prompt("Enter data")
        if (input) {
          setData(JSON.parse(input))
        }
        handleClose()
      },
    },
    {
      title: "Export",
      onClick: () => {
        // Log to catch cases that data is too long to display
        console.log(JSON.stringify(data))
        window.alert(JSON.stringify(data))
        handleClose()
      },
    },
  ]
  return (
    <PageContainer title="Jeopardy" actions={actions}>
      <Container
        sx={{
          width: "100%",
          paddingTop: "24px",
          backgroundColor: "black",
        }}
      >
        <ColumnHeaders data={data} setData={setData} edit={edit} />
        <QuestionRow
          point={100}
          onOpen={openQuestion}
          data={data}
          edit={edit}
          setData={setData}
        />
        <QuestionRow
          point={200}
          onOpen={openQuestion}
          data={data}
          edit={edit}
          setData={setData}
        />
        <QuestionRow
          point={300}
          onOpen={openQuestion}
          data={data}
          edit={edit}
          setData={setData}
        />
        <QuestionRow
          point={400}
          onOpen={openQuestion}
          data={data}
          edit={edit}
          setData={setData}
        />
        <QuestionRow
          point={500}
          onOpen={openQuestion}
          data={data}
          edit={edit}
          setData={setData}
        />
        <Box
          sx={{
            textAlign: "center",
            backgroundColor: "white",
            marginTop: "1rem",
            marginBottom: "1rem",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item flex={1} />
            <Grid item>
              <TextField type="number" variant="outlined" label={"Person 1"} />
            </Grid>
            <Grid item>
              <TextField type="number" variant="outlined" label={"Person 2"} />
            </Grid>
            <Grid item>
              <TextField type="number" variant="outlined" label={"Person 3"} />
            </Grid>
            <Grid item flex={1} />
          </Grid>
        </Box>
      </Container>

      <Dialog
        open={openDialog}
        fullScreen
        onClose={() => {
          setOpenDialog(false)
          setCurrentQuestion(undefined)
        }}
        sx={{
          opacity: 0.95,
        }}
        onClick={() => {
          setOpenDialog(false)
          setCurrentQuestion(undefined)
        }}
      >
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DialogContentText
            sx={{
              fontSize: "3rem",
            }}
          >
            {currentQuestion}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </PageContainer>
  )
}
