import { Typography } from "@mui/material"
import { PageContainer } from "../components"

export const MainPage = () => {
  return (
    <PageContainer title="Welcome!">
      <Typography variant="body1" sx={{ marginTop: "6px" }}>
        {"Welcome to Jmage90's website!"}
      </Typography>
    </PageContainer>
  )
}
