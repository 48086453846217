import axios from "axios"

const DEV = true

const BASE_PATH = !DEV
  ? "http://discord.jmage90.com:8080"
  : "http://localhost:8080"

export const getServers = () => {
  return axios.get(BASE_PATH + "/api/aws/ec2-servers")
}

export const startServer = (id: string) => {
  return axios.post(BASE_PATH + "/api/aws/ec2-servers/" + id + "/start")
}

export const stopServer = (id: string) => {
  return axios.post(BASE_PATH + "/api/aws/ec2-servers/" + id + "/stop")
}

export const getServer = (id: string) => {
  return axios.get(BASE_PATH + "/api/aws/ec2-servers/" + id)
}

export const getServerCost = (id: string) => {
  return axios.get(BASE_PATH + "/api/aws/ec2-servers/" + id + "/price")
}

export const updateServer = (id: string) => {
  return axios.get(BASE_PATH + "/api/aws/ec2-servers/" + id + "/update")
}
