import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemIcon,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import {
  AppRouterContextRouteType,
  useAppRouterContext,
} from "../../routes/AppRouterContext"

const useStyles = makeStyles({
  spacer: {
    marginRight: "5px",
  },
  list: {
    width: "auto",
    minWidth: "200px",
  },
  removePadding: {
    padding: 0,
  },
})

export const LeftNavbar = () => {
  const classes = useStyles()
  const { routes } = useAppRouterContext()

  return (
    <List className={classes.list} sx={{ padding: 0 }}>
      {routes?.map((route) => (
        <ListItemGroup {...route} key={route.path} />
      ))}
    </List>
  )
}

const ListItemGroup = ({
  path,
  icon,
  title,
  children,
  indentLevel = 0,
}: AppRouterContextRouteType & {
  indentLevel?: number
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  if (!title) {
    return <></>
  }

  return (
    <React.Fragment key={title}>
      <ListItem
        button
        onClick={() => {
          path && navigate(path)
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
        {children && (
          <IconButton
            className={classes.removePadding}
            onClick={(e) => {
              setOpen((prev) => !prev)
              e.stopPropagation()
            }}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </ListItem>
      <Divider />
      {children && children.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children?.map((child) => (
              <ListItemGroup
                {...child}
                path={`${path}/${child.path}`}
                indentLevel={indentLevel + 1}
                key={`${path}/${child.path}`}
              />
            ))}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  )
}
