import { useState, useEffect } from "react"
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from "recharts"
import { makeStyles } from "@mui/styles"
import { Typography, Paper, Card, CardActionArea } from "@mui/material"
import { CheckCircle, Cancel, HourglassEmpty } from "@mui/icons-material"
import { Server } from "./types"
import {
  startServer,
  stopServer,
  getServer,
  getServerCost,
} from "../../axios/server"
import { ActionsMenu } from "."

const useStyles = makeStyles({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardHeaderContainer: {
    padding: "15px 15px 0 15px",
  },
  subtitle: {
    color: "rgba(0, 0, 0, 0.54)",
    marginBottom: 10,
  },
  icon: {
    marginRight: 5,
  },
  success: {
    color: "green",
  },
  failure: {
    color: "red",
  },
  pending: {
    color: "orange",
  },
  card: {
    flexDirection: "column",
    display: "flex",
  },
  cardContent: {
    padding: 15,
  },
  money: {
    color: "#4CAF50",
  },
  dataContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  chart: {
    paddingTop: 10,
    marginBottom: 10,
  },
  totals: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
})

interface CardProps {
  server: Server
}

export const ServerCard = (props: CardProps) => {
  const classes = useStyles()
  const server = props.server

  const [costData, setCostData] = useState<
    Array<{ date: string; cost: number }>
  >([])
  const [totalCost, setTotalCost] = useState(0)

  useEffect(() => {
    if (!(costData.length > 0)) {
      getServerCost(server.id).then((resp) => {
        setCostData(
          resp.data.content.map(
            (day: {
              interval: { start: string; end: string }
              value: string
            }) => {
              const date = new Date(day.interval.start)
              return {
                date: date.getMonth() + 1 + "/" + date.getDate(),
                cost: Number(day.value),
              }
            }
          )
        )
        let total = 0
        resp.data.content.forEach(
          (day: {
            interval: { start: string; end: string }
            value: string
          }) => {
            total += Number(day.value)
          }
        )

        setTotalCost(total)
      })
    }
  }, [costData, setCostData, server, totalCost])

  const cardActions = [
    {
      action: () => {
        startServer(server.id)
      },
      title: "START",
    },
    {
      action: () => {
        stopServer(server.id)
      },
      title: "STOP",
    },
    {
      action: () => {
        getServer(server.id)
      },
      title: "REFRESH",
    },
  ]

  return (
    <Card
      onClick={() => {
        // TODO NAVIGATE TO SERVER PAGE
      }}
      className={classes.card}
    >
      <div className={classes.cardHeaderContainer}>
        <div className={classes.header}>
          <div className={classes.headerTitle}>
            {server.status === "ACTIVE" ? (
              <CheckCircle
                className={`${classes.icon} ${classes.success}`}
              ></CheckCircle>
            ) : server.status === "LOADING" ? (
              <HourglassEmpty
                className={`${classes.icon} ${classes.pending}`}
              ></HourglassEmpty>
            ) : (
              <Cancel className={`${classes.icon} ${classes.failure}`}></Cancel>
            )}
            <Typography variant="h5">{server.id}</Typography>
            <ActionsMenu server={server} actions={cardActions}></ActionsMenu>
          </div>
          <Typography variant="body1">{server.status}</Typography>
        </div>
        <Typography variant="subtitle2" className={classes.subtitle}>
          {server.hostName}
        </Typography>
      </div>

      <CardActionArea className={classes.cardContent}>
        <div className={classes.dataContainer}>
          <Paper className={classes.chart}>
            <LineChart width={350} height={200} data={costData}>
              <Line type="monotone" dataKey="cost" stroke="#8884d8" />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="date" />
              <YAxis />
            </LineChart>
          </Paper>
          <div className={classes.totals}>
            <Typography variant="h6">Total Cost: </Typography>
            <Typography variant="h3" className={classes.money}>
              ${totalCost.toFixed(2)}
            </Typography>
          </div>
        </div>
      </CardActionArea>
    </Card>
  )
}
