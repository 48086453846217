import { useNavigate } from "react-router-dom"
import { Toolbar, AppBar, Typography, Button, Box } from "@mui/material"

export type TopNavbarProps = {
  title: string
}

export const TopNavbar = ({ title }: TopNavbarProps) => {
  const navigate = useNavigate()

  return (
    <AppBar color="primary" position="sticky">
      <Toolbar>
        <Typography
          variant="h5"
          sx={{ marginLeft: "5px", cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          {title}
        </Typography>
        <Box sx={{ flex: 1 }} />
        <Button
          color="inherit"
          onClick={() => {
            window.alert("TODO: Create Logout dialog")
          }}
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  )
}
