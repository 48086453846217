import { PageContainer } from "../../components"
import { Outlet } from "react-router-dom"

export const GamesMainPage = () => {
  return (
    <PageContainer title="Games">
      <Outlet />
    </PageContainer>
  )
}
