import { Grid } from "@mui/material"
import QuestionCell from "./QuestionCell"
import { CategoryQuestions } from "./types"

const QuestionRow = ({
  data,
  point,
  onOpen,
  edit,
  setData,
}: {
  data: Array<CategoryQuestions>
  point: number
  onOpen: (content: string) => void
  edit: boolean
  setData: React.Dispatch<React.SetStateAction<Array<CategoryQuestions>>>
}) => {
  return (
    <Grid container spacing={2}>
      {data.map(({ questions }, index) => {
        return (
          <QuestionCell
            key={index}
            onOpen={onOpen}
            point={point}
            questions={questions}
            index={index}
            edit={edit}
            setData={setData}
          />
        )
      })}
    </Grid>
  )
}

export default QuestionRow
