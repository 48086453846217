import { Grid, Paper, Input, TextField } from "@mui/material"
import { useState } from "react"
import { CategoryQuestions } from "./types"

const QuestionCell = ({
  index,
  questions,
  point,
  onOpen,
  edit,
  setData,
}: {
  index: number
  questions: { [point: string]: string }
  point: number
  onOpen: (content: string) => void
  edit: boolean
  setData: React.Dispatch<React.SetStateAction<Array<CategoryQuestions>>>
}) => {
  const [disabled, setDisabled] = useState<boolean>(false)
  return (
    <Grid item flex={1}>
      <Paper
        onClick={() => {
          if (!edit && !disabled) {
            setDisabled(true)
            onOpen(questions[point] ?? "<empty>")
          }
          if (disabled) {
            setDisabled(false)
          }
        }}
        sx={{
          width: "100%",
          height: "100px",
          textAlign: "center",
          verticalAlign: "middle",
          lineHeight: "100px",
          margin: "auto",
          marginTop: "24px",
          backgroundColor: edit ? "white" : disabled ? "black" : "#1976d2",
          cursor: disabled ? undefined : "pointer",
          color: disabled ? "black" : "white",
          fontSize: "2rem",
        }}
      >
        {edit ? (
          <TextField
            variant="filled"
            multiline
            label={"$" + point}
            defaultValue={questions[point]}
            onChange={(event) => {
              setData((prev) => {
                prev[index].questions[point] = event.target.value
                return prev
              })
            }}
            InputProps={{ sx: { height: "100px" } }}
            maxRows={3}
          />
        ) : (
          "$" + point
        )}
      </Paper>
    </Grid>
  )
}

export default QuestionCell
