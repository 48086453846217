import React, { useMemo, useEffect, useState } from "react"
import { CircularProgress } from "@mui/material"
import { getServers } from "../axios"
import { PageContainer } from "../components"
import {
  MainPage,
  GamesMainPage,
  ServersMainPage,
  SingleServerMainPage,
  DinoGame,
  GameWrapper,
  JeopardyMainPage,
} from "../pages"
import {
  Apple,
  Home,
  QuestionAnswer,
  SportsEsports,
  Storage as StorageIcon,
} from "@mui/icons-material"

export type AppRouterContextRouteType = {
  path: string
  title?: string
  icon?: JSX.Element
  element: JSX.Element
  children?: Array<AppRouterContextRouteType>
}

export type RouterContextType = {
  routes?: Array<AppRouterContextRouteType>
}

export const AppRouterContext = React.createContext<RouterContextType>({})
export const useAppRouterContext = () => React.useContext(AppRouterContext)
export const AppRouterConsumer = AppRouterContext.Consumer

export const AppRouterProvider = (props: React.PropsWithChildren<unknown>) => {
  const [serverList, setServerList] = useState<Array<any>>([])

  const routes = useMemo(() => {
    return [
      {
        path: "/",
        title: "Home",
        icon: <Home />,
        element: <MainPage />,
      },
      {
        path: "/games",
        title: "Games",
        icon: <SportsEsports />,
        element: <GamesMainPage />,
        children: [
          {
            path: "apple",
            title: "Apple",
            icon: <Apple />,
            element: (
              <GameWrapper
                RunGame={(canvas, options) => DinoGame({ canvas, options })}
              />
            ),
          },
        ],
      },
      {
        path: "/servers",
        title: "Servers",
        icon: <StorageIcon />,
        element: <ServersMainPage />,
        // children: [
        //   {
        //     path: "",
        //     element: <ServerList />,
        //   },
        //   {
        //     path: "summary",
        //     title: "Summary",
        //     element: <ServersSummaryPage />,
        //   },
        // ],
      },
      ...serverList.map((server) => {
        return {
          path: "/servers/" + server.id,
          element: <SingleServerMainPage server={server} />,
        }
      }),
      {
        path: "/servers/*",
        element: (
          <PageContainer title="Loading server...">
            <></>
          </PageContainer>
        ),
      },
      {
        path: "/jeopardy",
        title: "Jeopardy",
        icon: <QuestionAnswer />,
        element: <JeopardyMainPage />,
      },
      {
        path: "*",
        element: <CircularProgress />,
      },
    ]
  }, [serverList])

  useEffect(() => {
    setTimeout(() => {
      getServers().then((response) => {
        setServerList(
          response?.data?.content
            ?.map((server: any) => {
              return server
            })
            .sort((a: any, b: any) => a.id.localeCompare(b.id))
        )
      })
    }, 2000) // TODO: Remove fake delay
  }, [])

  return (
    <AppRouterContext.Provider value={{ routes }}>
      {props.children}
    </AppRouterContext.Provider>
  )
}
