import { Routes, Route } from "react-router"
import { AppRouter } from "./routes/AppRouter"
import {
  AppRouterConsumer,
  AppRouterContextRouteType,
} from "./routes/AppRouterContext"

const App = () => {
  return (
    <AppRouter>
      <AppRouterConsumer>
        {({ routes }) => {
          return <Routes>{getRouteElements(routes)}</Routes>
        }}
      </AppRouterConsumer>
    </AppRouter>
  )
}

const getRouteElements = (routes?: Array<AppRouterContextRouteType>) => {
  return routes?.map((route) => {
    return (
      <Route path={route.path} element={route.element} key={route.path}>
        <>{getRouteElements(route.children)}</>
      </Route>
    )
  })
}

export default App
