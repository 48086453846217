import React from "react"
import { BrowserRouter } from "react-router-dom"
import { Box } from "@mui/material"
import { LeftNavbar, TopNavbar } from "../components"
import { AppRouterProvider } from "./AppRouterContext"

export const AppRouter = (props: React.PropsWithChildren<unknown>) => {
  return (
    <AppRouterProvider>
      <BrowserRouter>
        <TopNavbar title="Jmage90 Penthouse" />
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              borderRight: "1px solid",
              borderColor: "divider",
              minWidth: "256px",
              overflow: "auto",
            }}
          >
            <LeftNavbar />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              overflow: "auto",
              display: "flex",
            }}
          >
            {props.children}
          </Box>
        </Box>
      </BrowserRouter>
    </AppRouterProvider>
  )
}
