import { Grid, Input } from "@mui/material"
import { CategoryQuestions } from "./types"

type ColumnHeadersProps = {
  data: Array<CategoryQuestions>
  setData: React.Dispatch<React.SetStateAction<Array<CategoryQuestions>>>
  edit: boolean
}

const ColumnHeaders = ({ data, setData, edit }: ColumnHeadersProps) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        alignItems: "center",
      }}
    >
      {data.map(({ columnName }, index) => {
        return (
          <Grid
            key={index}
            item
            flex={1}
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              color: "white",
              fontSize: "1.5rem",
            }}
          >
            {edit ? (
              <Input
                defaultValue={columnName}
                onChange={(event) => {
                  setData((prev) => {
                    prev[index].columnName = event.target.value
                    return prev
                  })
                }}
                sx={{
                  backgroundColor: "white",
                }}
              />
            ) : (
              columnName
            )}
          </Grid>
        )
      })}
    </Grid>
  )
}

export default ColumnHeaders
