import { useState, useEffect } from "react"
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material"
import { getServers, startServer, updateServer, stopServer } from "../../axios"
import { PageContainer } from "../../components"
import { Server } from "./types"

const ServerCard = ({ server }: { server: Server }) => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "500px",
        marginTop: "12px",
        border: "1px solid",
        borderColor: "divider",
      }}
    >
      <CardHeader
        title={server.id}
        sx={{ borderBottom: "1px solid", borderColor: "divider" }}
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography>{server.status}</Typography>
        <Button
          variant="contained"
          onClick={() => {
            startServer(server.id)
          }}
        >
          {"Load worlds"}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            updateServer(server.id)
          }}
        >
          {"update"}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            stopServer(server.id)
          }}
        >
          {"stop"}
        </Button>
      </CardContent>
      <CardActions sx={{ borderTop: "1px solid", borderColor: "divider" }}>
        {"actions"}
      </CardActions>
    </Card>
  )
}

export const ServersMainPage = () => {
  const [serverList, setServerList] = useState<Array<any>>([])

  useEffect(() => {
    setTimeout(() => {
      getServers().then((response) => {
        setServerList(
          response?.data?.content
            ?.map((server: any) => {
              return server
            })
            .sort((a: any, b: any) => a.id.localeCompare(b.id))
        )
      })
    }, 2000) // TODO: Remove fake delay
  }, [])

  return (
    <PageContainer title={"Servers"}>
      <Box sx={{ flexGrow: 1, display: "flex", gap: "12px" }}>
        {serverList.map((server) => {
          return <ServerCard server={server} />
        })}
      </Box>
    </PageContainer>
  )
}
