import React from "react"
import { Box } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  canvasContainer: {
    background: "#eee",
    padding: "24px",
  },
  canvas: {
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
})

export const GameWrapper = ({
  RunGame,
}: {
  RunGame: (
    canvas: HTMLCanvasElement,
    options: { width: number; height: number }
  ) => void
}) => {
  const classes = useStyles()
  const canvasRef = React.useRef<HTMLCanvasElement>(null)

  React.useEffect(() => {
    if (canvasRef.current != null) {
      RunGame(canvasRef.current, { width: 1000, height: 600 })
    }
  }, [RunGame])

  return (
    <Box className={classes.canvasContainer}>
      <canvas
        ref={canvasRef}
        className={classes.canvas}
        width={1000}
        height={600}
      />
    </Box>
  )
}
