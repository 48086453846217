import React from "react"
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material"
import { Menu as MenuIcon } from "@mui/icons-material"

export type PageContainerProps = React.PropsWithChildren<{
  title: string
  actions?: {
    title: string
    onClick: () => void
  }[]
}>

export const PageContainer = ({
  title,
  actions,
  children,
}: PageContainerProps) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const hasActions = React.useMemo(() => {
    return actions && actions.length > 0
  }, [actions])

  return (
    <Box
      sx={{
        padding: "12px",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar>
        <Typography variant="h4" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <Toolbar>
          {hasActions && (
            <IconButton
              onClick={() => setOpen(true)}
              edge="end"
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
        {hasActions && (
          <Drawer open={open} anchor="right" onClose={() => setOpen(false)}>
            <List sx={{ width: "auto", minWidth: "300px" }}>
              {actions?.map((action) => {
                return (
                  <React.Fragment key={action.title}>
                    <ListItem button>
                      <ListItemText
                        primary={action.title}
                        onClick={action.onClick}
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                )
              })}
            </List>
          </Drawer>
        )}
      </Toolbar>
      <Divider />
      {children}
    </Box>
  )
}
