import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import {
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material"
import { ArrowDropDown } from "@mui/icons-material"
import { Server } from "./types"

const useStyles = makeStyles({
  dropDownButton: {
    marginLeft: 5,
  },
  list: {
    width: 100,
    padding: 0,
  },
  item: { textAlign: "center" },
})

type Action = {
  action: () => void
  title: string
}

interface ActionsMenuProps {
  actions: Array<Action>
  server: Server
}

export const ActionsMenu = (props: ActionsMenuProps) => {
  const classes = useStyles()
  const [openActions, setOpenActions] = useState(false)
  const [anchorElement, setAnchorElement] = useState<any>()

  return (
    <div>
      <IconButton
        id="actions-button"
        onClick={(e) => {
          setAnchorElement(e.currentTarget)
          setOpenActions(true)
        }}
        className={classes.dropDownButton}
        size="small"
      >
        <ArrowDropDown />
      </IconButton>
      <Popover
        open={openActions}
        anchorEl={anchorElement}
        onClose={() => {
          setAnchorElement(null)
          setOpenActions(false)
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List className={classes.list}>
          {props.actions.map((action, index) => {
            return (
              <React.Fragment key={"actions-" + index}>
                <ListItem
                  className={classes.item}
                  button
                  key={props.server.id + "-" + index}
                  onClick={() => {
                    setOpenActions(false)
                    action.action()
                  }}
                >
                  <ListItemText
                    className={classes.item}
                    primary={action.title}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            )
          })}
        </List>
      </Popover>
    </div>
  )
}
